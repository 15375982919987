import * as client_hooks from '../../../src/app/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45')
];

export const server_loads = [0,2,6];

export const dictionary = {
		"/(app)": [7,[2],[3]],
		"/(app)/artists": [9,[2],[3]],
		"/(app)/artists/create": [12,[2],[3]],
		"/(app)/artists/[id]": [10,[2],[3]],
		"/(app)/artists/[id]/edit": [11,[2],[3]],
		"/(app)/artworks/create": [15,[2],[3]],
		"/(app)/artworks/[id]": [13,[2],[3]],
		"/(app)/artworks/[id]/edit": [14,[2],[3]],
		"/(app)/auctions": [16,[2],[3]],
		"/(app)/auctions/create": [19,[2],[3]],
		"/(app)/auctions/[id]": [17,[2],[3]],
		"/(app)/auctions/[id]/edit": [18,[2],[3]],
		"/(auth)/change-email": [36,[6]],
		"/(auth)/change-password": [37,[6]],
		"/(auth)/check-email": [~38,[6]],
		"/(auth)/confirm-email/[token]": [~39,[6]],
		"/(app)/documents": [20,[2],[3]],
		"/(app)/documents/create": [23,[2],[3]],
		"/(app)/documents/[id]": [21,[2],[3]],
		"/(app)/documents/[id]/edit": [22,[2],[3]],
		"/(app)/notifications": [24,[2],[3]],
		"/(app)/profile/gallery/(profile)/employees": [28,[2,5],[3]],
		"/(app)/profile/gallery/(employee)/employees/create": [27,[2,4],[3]],
		"/(app)/profile/gallery/(employee)/employees/[id]": [25,[2,4],[3]],
		"/(app)/profile/gallery/(employee)/employees/[id]/edit": [26,[2,4],[3]],
		"/(app)/profile/gallery/(profile)/user-settings": [29,[2,5],[3]],
		"/(auth)/request-reset-password": [~40,[6]],
		"/(app)/requests": [30,[2],[3]],
		"/(app)/requests/[id]": [31,[2],[3]],
		"/(auth)/reset-password": [~41,[6]],
		"/(restricted)/restricted": [~45],
		"/(app)/sandbox": [32,[2],[3]],
		"/(auth)/sign-in": [~42,[6]],
		"/(auth)/sign-out": [43,[6]],
		"/(auth)/sign-up": [~44,[6]],
		"/(app)/store-configurations": [33,[2],[3]],
		"/(app)/store-configurations/edit": [34,[2],[3]],
		"/(app)/transactions": [35,[2],[3]],
		"/(app)/[...unexpected]": [8,[2],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';